/*global CustomFunctions */
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { DdbEnvironment, environmentContextService, unpaginate, validateEnvironment } from "../../shared/ddb";

class ProjectIdBatcher extends Batcher<string> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, string>> {
    const env = environmentContextService(environment);
    return await toMap(
      unpaginate(
        (after) => env.getProjects({ number: keys, after }),
        (data) => data.data.projects,
        (data) => data.data.paging?.cursors?.after
      ),
      (project) => project.number,
      (project) => project.project_id
    );
  }
}

const projectIdBatcher = new ProjectIdBatcher(10);

/**
 * Gets DDB project ID.
 * @customfunction PROJECT.PROJECT_ID
 * @param environment The DDB environment to use.
 * @param projectNumber Project number without a dash.
 * @returns The DDB project ID.
 * @cancelable
 */
export async function projectId(
  environment: string,
  projectNumber: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<string> {
  console.log("projectId");

  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }

  if (!/^\d{8}$/.test(projectNumber)) {
    throw new CustomFunctions.Error(
      CustomFunctions.ErrorCode.invalidValue,
      "Invalid project number. Project number must be 8 digits."
    );
  }

  return await projectIdBatcher.enqueue(environment, projectNumber, invocation);
}

CustomFunctions.associate("PROJECT.PROJECT_ID", projectId);