/*global CustomFunctions */
import { ParameterSchema } from "@ddb/parameter-service";
import { toMap } from "../../shared/asyncIterator";
import { Batcher } from "../../shared/batch";
import { DdbEnvironment, parameterService, unpaginate, validateEnvironment } from "../../shared/ddb";
import { isGuid } from "../../shared/helpers";

class AssetParametersBatcher extends Batcher<ParameterSchema> {
  async _getResults(environment: DdbEnvironment, keys: string[]): Promise<Map<string, ParameterSchema>> {
    const client = parameterService(environment);
    const [assetIds, parameterTypeIds] = keys
      .map((key) => key.split(":") as [string, string])
      .reduce(
        ([assetIds, parameterTypeIds], [assetId, parameterTypeId]) => [
          assetIds.add(assetId),
          parameterTypeIds.add(parameterTypeId),
        ],
        [new Set<string>(), new Set<string>()]
      );
    return await toMap(
      unpaginate(
        (after) =>
          client.getAllParameters({
            after,
            assetId: Array.from(assetIds),
            parameterTypeId: Array.from(parameterTypeIds),
          }),
        (data) => data.data.parameters,
        (data) => data.data.paging?.cursors?.after
      ),
      (parameter) => `${parameter.parents[0].id}:${parameter.parameter_type.id}`
    );
  }
}

const asssetParametersBatcher = new AssetParametersBatcher(10);

/**
 * Gets the ID of a parameter on an asset.
 * @customfunction PARAMETER.ASSET_PARAMETER_ID
 * @param environment The DDB environment to use.
 * @param assetId The asset ID.
 * @param parameterTypeId The parameter type ID.
 * @returns The parameter ID.
 * @cancelable
 */
export async function assetParameterId(
  environment: string,
  assetId: string,
  parameterTypeId: string,
  invocation: CustomFunctions.CancelableInvocation
): Promise<string> {
  if (!validateEnvironment(environment)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Invalid environment.");
  }
  if (!isGuid(assetId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Asset ID must be a GUID.");
  }
  if (!isGuid(parameterTypeId)) {
    throw new CustomFunctions.Error(CustomFunctions.ErrorCode.invalidValue, "Parameter type ID must be a GUID.");
  }
  const parameter = await asssetParametersBatcher.enqueue(environment, `${assetId}:${parameterTypeId}`, invocation);
  return parameter.id;
}

CustomFunctions.associate("PARAMETER.ASSET_PARAMETER_ID", assetParameterId);